<template>
    <div class="row g-5 g-xxl-8 mx-0 bank-follow-table">
        <div class="card mb-5 mb-xl-10 py-2">
            <div class="card-header px-0">
                <div
                    class="card-title w-100 d-flex justify-content-between m-0 flex-column flex-md-row align-items-md-center">
                    <h3 class="fw-bolder m-0 ps-3">
                        {{ $t("Followed Product Areas") }}
                        <span class="count-label ms-2">{{ userDetails.followed_product_areas.length }}</span>
                    </h3>

                    <div class="d-flex align-items-center">
                        <p class="ps-3 mb-0 me-10 info-section-text">
                            <a :href="`mailto:${platformConfigs.platformEmails.ContactEmail}`">
                                {{ $t("Contact PLATFORMNAME", {platformName: platformConfigs.platformName}) }}
                            </a>
                            {{ $t("to add more Product Areas") }}
                        </p>
                        <!--            <div class="search-countries">-->
                        <!--              <el-select-->
                        <!--                  v-model="value"-->
                        <!--                  multiple-->
                        <!--                  filterable-->
                        <!--                  default-first-option-->
                        <!--                  :reserve-keyword="false"-->
                        <!--                  :placeholder="$t('Search Product Areas')"-->
                        <!--                  collapse-tags-->
                        <!--              >-->
                        <!--                <el-option-->
                        <!--                    v-for="(item, index) in productAreas"-->
                        <!--                    :key="index"-->
                        <!--                    :label="item"-->
                        <!--                    :value="item"-->
                        <!--                />-->
                        <!--              </el-select>-->
                        <!--            </div>-->
                    </div>
                </div>
            </div>

            <div class="table-wrapper responsive-table">
                <el-table
                    :data="userDetails.bank_product_areas"
                    :show-header="false"
                    style="width: 100%">
                    <el-table-column prop="name" class-name="bank-follow-table__name">
                        <template #default="scope">
                            <h1>{{ scope.row.name }}</h1>
                        </template>
                    </el-table-column>
                    <el-table-column prop="num_1" class-name="bank-follow-table__num_1" width="80">
                        <template #default="scope">
                            <div class="follow-status" :class="getStatusClass(scope.row.score)">
                                {{ scope.row.score }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="num_2" class-name="bank-follow-table__num_2" width="80">
                        <template #default="scope">
                            <div class="follow-status">
                                {{ scope.row.importance }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="followed" class-name="bank-follow-table__followed" width="250">
                        <template #default="scope">
                            <div class="pe-sm-5">
                                <FollowingButton
                                    :title="$t('Follow Product Area')"
                                    type="outline"
                                    :loading="loading[scope.row.name]"
                                    :status="userDetails.followed_product_areas.includes(scope.row.name)"
                                    @click="triggerCountryFollow(scope.row.name)"
                                />
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { getStatusClass } from "@/core/helpers/GlobalHelper";
import FollowingButton from "@/buying-teams/shared-components/utils/FollowingButton";
import { BankUserSettingsContainer } from "@/store/models/bank/BankUserSettingsContainer";
import store from "@/store";
import BankFollowType from "@/store/enums/bank/BankFollowType";
import BankFollowOperationEnum from "@/store/enums/bank/BankFollowOperationEnum";
import { DataService } from "@/core/services/DataService";

export default {
    name: "FollowedProductAreas",
    components: { FollowingButton },
    props: {
        userDetails: BankUserSettingsContainer
    },
    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        },
        bank() {
            return store.getters.bank;
        }
    },
    data() {
        return {
            getStatusClass,
            value: [],
            loading: {}
        };
    },
    methods: {
        async triggerCountryFollow(productArea) {
            this.loading[productArea] = true;

            const operation = this.userDetails.followed_product_areas.includes(productArea)
                ? BankFollowOperationEnum.REMOVE
                : BankFollowOperationEnum.ADD;

            await store.dispatch("updateBankUserFollows", {
                type: BankFollowType.PRODUCT_AREA,
                operation,
                items: [productArea]
            }).then(async () => {
                await store.dispatch("reInitFiltersData");

                this.bank.addRemoveProductArea(productArea);
                this.userDetails.addRemoveProductArea(operation, productArea);
                DataService.storeBankData(this.bank);
            }).finally(() => {
                this.loading[productArea] = false;
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.info-section-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
}
</style>
